<template>
  <div>
    <section class="section1 ">
      <div class="seccionlore1 backverde container-fluid">
        <div class="row">
          <div class="col-9 pe-0">
            <p class="titulo1 textoverde tituloGrandeCentrado">
              Los considero pequeñas obras de arte <br>
              en la vida cotidiana
            </p>
            <p class="texto2 textoverde pe-2">
              Los carteles son comunicados visuales publicitarios cuyo objetivo
              es dar a conocer información acerca de diversas actividades de
              interés público o social; que van desde promocionar un producto
              o servicio, hasta publicitar actividades del mundo del espectáculo,
              de deportes, de negocios, de diversión, entre otras muchas más.
            </p>
          </div>
          <div class="col-3 ps-0">
            <img src="../assets/imagenes/inicio/catarina.png" alt="Lapiz" class="conchita ">
          </div>
        </div>
      </div>
    </section>

    <!----------------------CONTENIDO----------------------->

    <!----------------------Fila 1----------------------->
    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-6 columna">
          <div>
            <img
                src="../assets/imagenes/cyp/ser_vecinos.png"
                alt=""
                class="poster1"
            />
            <p
                style="text-align: left; margin-left: 4.5em;"
                class="texto4 textogris"
            >
              <b>Cliente:</b> I.T.E.S.M. <br/>
              <b>Lugar: </b>Monterrey, México <br/>

            </p>
          </div>
        </div>

        <div class="col-6 columna">
          <div>
            <img
                src="../assets/imagenes/cyp/hay_otra_forma.png"
                alt=""
                class="poster1"
            />

            <p
                style="text-align: left; margin-left: 4.5em;"
                class="texto4 textogris"
            >
              <b>Cliente:</b> Avnatural <br/>
              <b>Lugar: </b>Madrid, España<br/>

            </p>
          </div>
        </div>
      </div>
    </div>


    <!--------------------Fila 2----------------------->
    <div class="container-fluid contimg">
      <div class="row">
        <div class="col-12 columna">
          <div>
            <img
                src="../assets/imagenes/cyp/recorrido.png"
                alt=""
                class="recorrido"
            />
            <p
                style="text-align: left; margin-left: 12em"
                class="texto4 textogris"
            >
              <b>Cliente:</b> Colonia Mexicana en Madrid (2015) <span class="letraPeque">diseño inspirado en Adriana Muñoz</span> <br/>
              <b>Lugar: </b>Madrid, España <br/>

            </p>
            <div>
              <img
                  src="../assets/imagenes/cyp/taller_infantil.png"
                  alt=""
                  class="taller"
              />

            </div>
          </div>
        </div>
      </div>
    </div>

    <!--------------------Fila 3----------------------->

    <div style="margin-bottom: 8%;" class="container-fluid contimg ">
      <div class="col-12 columna">
        <div>
          <img
              src="../assets/imagenes/cyp/cultura.png"
              alt="cultura"
              class="taller"
          />
        </div>
        <div class="mt-"><p
            style="text-align: left; margin-left: 15em"
            class="texto4 textogris"
        >
          <b>Cliente:</b> Museo de América <br/>
          <b>Lugar:</b> Madrid, España <br/>

        </p></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CartelesPosters",
  mounted() {
    this.$store.commit("SET_FOOTER", [true,'backverde']);
  }
}
</script>

<style scoped>

</style>